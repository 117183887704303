<template>
  <div class="home">
    <h1>Welcome to FlixMatch</h1>
    <h2>home of great games for movie lovers and film experts</h2>
    <SiteList :items="siteList" />
  </div>
</template>

<script>
import SiteList from './SiteList.vue'
import { API_URL } from '@/config'

export default {
  name: 'HomeView',
  components: {
    SiteList
  },
  data() {
    return {
      siteList: []
    }
  },
  created() {
    // Fetch data when the component is created
    this.fetchData()
  },
  methods: {
    fetchData() {

      fetch(`${API_URL}/sites`)
        .then(response => response.json())
        .then(data => {
          this.siteList = data
          this.$store.commit('SET_SHARED_VALUE', this.siteList)

        })
        .catch(error => console.error('Error:', error))
    }
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
