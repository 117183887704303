import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    sharedValue: ''
  },
  mutations: {
    SET_SHARED_VALUE(state, value) {
      state.sharedValue = value
    }
  },
  actions: {
    updateSharedValue({ commit }, value) {
      commit('SET_SHARED_VALUE', value)
    }
  },
  getters: {
    getSharedValue: state => state.sharedValue
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
