<template>
  <div class="container" id="result">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <router-link :to="{ name: 'Result', params: { id: item.id_site }}">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SiteList',
  props: {
    items: Array
  }
}
</script>
