<template>
  <div id="app">
    <header>
      <div id="logo"></div>
      <nav>
        <router-link to="/">Home</router-link>
      </nav>
    </header>

    <main>
      <router-view></router-view>
    </main>

    <footer>
      <p>&copy; 2023 FlixMatch. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Anton');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #aaa;
  font-size: 18px;
  background: #ffffff url("@/assets/background.png") no-repeat center top fixed;
  background-size: cover;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures the app takes at least the full viewport height */
}

header {
  padding: 20px;
}

#logo {
  margin: 50px auto;
  background-image: url("@/assets/logo.png");
  width: 444px;
  height: 88px;
}

nav {
  text-align: center;
}

main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

footer {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  font-size: small;
}

a, a:visited, a:link {
  color: white;
  text-decoration: none;
}

h1 {
  color: #fff;
  letter-spacing: 0px;
  font-family: 'Anton', sans-serif;
  font-size: 60px;
  font-weight: 200;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
</style>
