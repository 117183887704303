<template>
  <div class="container" id="result">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Start</th>
          <th>Ziel</th>
          <th>Schritte</th>
          <th>Zeit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.name">
          <td>{{ item.name }}</td>
          <td>{{ getStartPath(item.result) }}</td>
          <td>{{ getEndPath(item.result) }}</td>
          <td>{{ item.steps }}</td>
          <td>{{ item.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ResultTable',
  props: {
    list: Array
  },
  methods: {
    getStartPath(result) {
      const paths = result.split('||');
      return paths[0].split(':')[1];
    },
    getEndPath(result) {
      const paths = result.split('||');
      return paths[paths.length - 1].split(':')[1];
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Anton');

html, body {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    color: #aaa;
    font-size: 18px;
    background: #ffffff url("@/assets/background.png") no-repeat center top fixed;
    background-size: cover;
}

a, a:visited, a:link {
    color: white;
}

#wrapper {
    min-height:100vh;
    display: flex;
    flex-direction: column;
}

header {
    height: 150px;
    font-size: small;
}

footer {
    height: 30px;
    font-size: small;
}

main {
    flex: 1;
}

#home {
    padding-top: 5px;
}

h1 {
    color: #fff;
    letter-spacing: 0px;
    font-family: 'Anton', sans-serif;
    font-size: 60px;
    font-weight: 200;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
}

table {
    border-collapse: collapse;
}

.container {

}

#result table {
    border: 1px solid #333;
    border-style: groove;
    margin: 10px auto;
    overflow: auto;
}

#result table thead {
    border-bottom: 1px solid white;
}

#result table thead th {
    font-size: large;
    padding: 5px 50px;
}

#result table tbody td {
    padding: 2px 10px;
}

#sites ul {
    padding-top: 30px;
    list-style-type: none;
    text-transform: uppercase;
}

.logo {
    margin: 50px auto;
    background-image: url("@/assets/logo.png");
    width: 444px;
    height: 88px;
    border: 1px solid yellow;
}
</style>
