<template>
  <div class="result">
    <h1>Results for {{ siteName }}</h1>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <ResultTable :list="resultList" />
    </div>
  </div>
</template>

<script>
import ResultTable from './ResultTable.vue'
import { API_URL } from '@/config'

export default {
  name: 'ResultView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ResultTable
  },
  data() {
    return {
      resultList: [],
      loading: false,
      error: null,
      localSitesList: null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler: 'fetchResults'
    }
  },
  computed: {
    sitesList() {
      // Use this.$store.state to access state directly
        return this.$store.state.sharedValue || this.localSitesList

      // Alternatively, use this.$store.getters to access via a getter
      // return this.$store.getters.getSharedValue
    },
    siteName() {
      return this.sitesList.find(item => item.id_site == this.id).name
    }
  },
  created() {
    this.fetchSites()
  },
  methods: {
    async fetchResults() {
      this.loading = true;
      this.error = null;
      try {
        const response = await fetch(`${API_URL}/result/${this.id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        this.resultList = await response.json();
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data. Please try again later.';
      } finally {
        this.loading = false;
      }
    },
    async fetchSites() {
      if (this.$store.state.sharedValue) {
        return
      }
      try {
        const response = await fetch(`https://flixmatch.net/api/sites`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        this.localSitesList = await response.json();
        this.$store.commit('SET_SHARED_VALUE', this.localSitesList)
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data. Please try again later.';
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
